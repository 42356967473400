import { DateTime } from 'luxon'
import { object, string, array, number, date, ref, boolean } from 'yup'
// constants
import { PRODUCT_PRICE_TYPE_ENUM } from '~/constants'

// i18n
import { t } from '@lingui/macro'

const createOrUpdate = (i18n) =>
  object()
    .shape({
      name: string()
        .max('120', i18n._(t`Nama maksimal 120 karakter`))
        .required(i18n._(t`Nama harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .required(i18n._(t`Deskripsi harus diisi!`)),
      highlightImages: array()
        .of(
          object()
            .shape({
              imageURL: string()
                .transform((value) => value || null)
                .required(i18n._(t`URL gambar harus diisi!`))
            })
            .noUnknown()
        )
        .min(1, i18n._(t`Minimal 1 gambar harus diisi!`)),
      price: number()
        .typeError(i18n._(t`Pastikan harga valid!`))
        .when('priceType', {
          is: 'free',
          then: (schema) =>
            schema
              .min(0, i18n._(t`Harga harus 0 ketika tipe harga produk gratis!`))
              .max(
                0,
                i18n._(t`Harga harus 0 ketika tipe harga produk gratis!`)
              ),
          otherwise: (schema) =>
            schema.min(1000, i18n._(t`Harga minimal Rp 1.000`))
        })
        .required(i18n._(t`Harga harus diisi!`)),
      priceType: string()
        .oneOf(
          PRODUCT_PRICE_TYPE_ENUM,
          i18n._(
            t`Tipe harga harus salah satu dari: tetap, minimum, gratis, donasi, tip`
          )
        )
        .required(i18n._(t`Tipe harga harus diisi!`)),
      priceBeforeDiscount: number()
        .typeError(i18n._(t`Pastikan harga asli valid!`))
        .moreThan(
          ref('price'),
          i18n._(t`Harga asli harus lebih besar dari harga final!`)
        )
        .notOneOf([0], i18n._(t`Harga asli tidak boleh 0!`))
        .default(null)
        .nullable()
        .transform((value, originalValue) =>
          originalValue === '' || originalValue === null ? null : value
        ),
      startSaleAt: date()
        .typeError(i18n._(t`Pastikan tanggal mulai valid!`))
        .transform((_, originalValue) =>
          originalValue
            ? DateTime.fromISO(originalValue).startOf('day').toJSDate()
            : null
        )
        .nullable(true)
        .default(null),
      endSaleAt: date()
        .typeError(i18n._(t`Pastikan tanggal berakhir valid!`))
        .min(
          ref('startSaleAt'),
          i18n._(t`Tanggal berakhir harus setelah tanggal mulai`)
        )
        .transform((_, originalValue) =>
          originalValue
            ? DateTime.fromISO(originalValue).endOf('day').toJSDate()
            : null
        )
        .nullable(true)
        .default(null),
      maxRegistrant: number()
        .typeError(i18n._(t`Pastikan maksimal transaksi valid!`))
        .min(1, i18n._(t`Maksimal transaksi minimal 1`))
        .nullable(true)
        .default(null)
        .transform((v) => v || null),
      maxRegistrantPerTransaction: number()
        .typeError(i18n._(t`Pastikan maksimum pembelian per transaksi valid!`))
        .min(1, i18n._(t`Maksimum pembelian per transaksi minimal 1`))
        .default(1)
        .transform((v) => v || 1),
      isOnGoing: boolean()
        .typeError(i18n._(t`Pastikan status berlangsung valid!`))
        .required(i18n._(t`Status berlangsung harus diisi!`)),
      isShowTransactionCount: boolean()
        .typeError(i18n._(t`Pastikan menampilkan jumlah transaksi valid!`))
        .required(i18n._(t`Menampilkan jumlah transaksi harus diisi!`)),
      isUnlisted: boolean()
        .typeError(i18n._(t`Pastikan setting produk unlisted valid!`))
        .required(i18n._(t`Setting produk unlisted harus diisi!`)),
      isCustomFormEnabled: boolean()
        .typeError(i18n._(t`Pastikan setting formulir transaksi valid!`))
        .required(i18n._(t`Setting formulir transaksi harus diisi!`)),
      isCustomFormRequired: boolean()
        .typeError(
          i18n._(t`Pastikan setting formulir transaksi required valid!`)
        )
        .required(i18n._(t`Setting formulir transaksi required harus diisi!`))
    })
    .noUnknown(true)

const ProductValidator = {
  createOrUpdate
}

export default ProductValidator
